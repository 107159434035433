import { gsap } from 'gsap' // eslint-disable-line no-unused-vars

const Power1EaseInOut = gsap.parseEase('power1.inOut')
// const Power2EaseOut = gsap.parseEase('power2.inOut')

export default class ShadowTexture {
  constructor (isCastShadow = false) {
    const canvas = document.createElement('canvas')

    canvas.width = 512
    canvas.height = 512

    this.ctx = canvas.getContext('2d')
    this.ctxWidth = canvas.width
    this.ctxHeight = canvas.height

    this.clear()

    const template = document.createElement('template')
    template.innerHTML = `<div class="shadow-texture${(isCastShadow) ? ' cast-shadow-texture' : ''}"></div>`

    this.DOM = template.content.firstChild
    this.DOM.appendChild(canvas)
  }

  clear () {
    this.ctx.setTransform()
    this.ctx.clearRect(0, 0, this.ctxWidth, this.ctxHeight)
  }

  // This is the shadow on the sides of the cubes when they spin
  updateEdgeShadowPercent (percent, side = 'left') {
    this.DOM.style.visibility = (percent === 0 || percent === 1) ? 'hidden' : 'visible'

    this.clear()

    if (side === 'right') percent = 1 - percent

    // Adjust the shadow to move more circular
    const circPerc = Power1EaseInOut(percent)

    // Gradient stops to define the shadow edge
    let stops = [0, circPerc, 1]
    if (side === 'right') stops = [1, 1 - circPerc, 0]

    const shadowStrength = circPerc

    // Defines how "sharp" and transparent the shadow gradient is
    const grd = this.ctx.createLinearGradient(0, 0, this.ctxWidth, 0)
    grd.addColorStop(stops[0], `rgba(0, 0, 0, ${1})`)
    grd.addColorStop(stops[1], `rgba(0, 0, 0, ${1 * shadowStrength})`)
    grd.addColorStop(stops[2], `rgba(0, 0, 0, ${1 * shadowStrength})`)
    this.ctx.fillStyle = grd

    this.ctx.fillRect(0, 0, this.ctxWidth, this.ctxHeight)
  }

  updateCastShadowPercent (percent, offsetXPerc = 0.5, offsetYPerc = -0.5, scaleX = 1, scaleY = 1) {
    this.DOM.style.visibility = (percent === 0 || percent === 1) ? 'hidden' : 'visible'

    this.clear()

    const rot = percent * (90 * Math.PI / 180)

    this.ctx.filter = 'blur(4px)'

    this.ctx.translate(this.ctxWidth * offsetXPerc, this.ctxWidth * offsetYPerc)
    this.ctx.scale(scaleX, scaleY)
    this.ctx.rotate(rot)

    const grd = this.ctx.createLinearGradient(0, 0, this.ctxWidth, this.ctxHeight)
    grd.addColorStop(0.7, 'rgba(0,0,0,1)')
    grd.addColorStop(1, 'rgba(0,0,0,0)')
    this.ctx.fillStyle = grd

    this.ctx.fillRect(0, 0, this.ctxWidth, this.ctxHeight)
  }
}
