import { gsap, ScrollTrigger } from 'gsap/all' // eslint-disable-line no-unused-vars
import CustomEase from '../../lib/CustomEase.js'
import ShadowTexture from './ShadowTexture'

gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollTrigger)

export default class BasePanel {
  constructor (index, configProperties, ejsTemplate) {
    // Stacking order on page
    this.index = index

    this.customEase = CustomEase.create('custom', 'M0,0 C0.294,0.368 0.44,0.716 0.552,0.9 0.678,1.108 0.886,1.032 1,1')
    this.shadowCastToPanel = null
    this.detailsRevealed = false
    this.enable3dFaces = false
    this.isRevealing = false

    this.DOM = this.createDOMFromTemplate(ejsTemplate, configProperties)
    this.facesGroup = this.DOM.querySelector('.faces-group')
    // Used to calculate positions since the "group" is undergoing 3d transforms
    this.panelBounds = this.DOM.querySelector('.panel-bounds')

    this.heroFace = this.facesGroup.querySelector('.hero-face')
    this.castShadow = new ShadowTexture(true)
    this.heroFace.appendChild(this.castShadow.DOM)

    // If there is a details panel, then create 3d sides
    if (this.facesGroup.querySelector('.details-face')) {
      this.enable3dFaces = true

      this.detailsFace = this.facesGroup.querySelector('.details-face')
      this.topFace = this.facesGroup.querySelector('.top-face')
      this.bottomFace = this.facesGroup.querySelector('.bottom-face')

      this.leftShadow = new ShadowTexture()
      this.heroFace.appendChild(this.leftShadow.DOM)

      this.rightShadow = new ShadowTexture()
      this.detailsFace.appendChild(this.rightShadow.DOM)
    }

    this.revealTransitionPercent = 0
    this.revealTimeoutRef = 0

    ScrollTrigger.create({
      trigger: this.panelBounds,
      start: 'top 30%',
      end: 'bottom 60%',
      onToggle: self => {
        this.onToggleInView(self)
      }
    })

    ScrollTrigger.refresh()
  }

  createDOMFromTemplate (ejsTemplate, properties) {
    const template = document.createElement('template')
    template.innerHTML = ejs.render(ejsTemplate, properties)
    return template.content.firstChild
  }

  set revealTransitionPercent (percent) {
    this._revealTransitionPercent = percent
    this.isRevealing = true

    const halfFaceWidth = this.heroFace.offsetWidth / 2
    const faceHeight = this.heroFace.offsetHeight

    const rotation = -this._revealTransitionPercent * 90
    const zMovement = 100 * ((Math.abs((percent * 4) % 4 - 2) / 2) - 1)

    // Rotate the containing object
    this.facesGroup.style.transform = `translateZ(${-halfFaceWidth + zMovement}px) rotateY(${rotation}deg)`
    this.heroFace.style.transform = `translateZ(${halfFaceWidth}px)`

    // Remainder of transforms only if this panel is 3d
    if (this.enable3dFaces) {
      this.detailsFace.style.transform = `translateX(${halfFaceWidth}px) rotateY(${90}deg)`
      this.topFace.style.transform = `translateY(${-halfFaceWidth}px) rotateX(90deg)`
      this.bottomFace.style.transform = `translateY(${faceHeight - halfFaceWidth}px) rotateX(-90deg)`

      if (percent === 0) {
        this.topFace.style.visibility = 'hidden'
        this.bottomFace.style.visibility = 'hidden'
        this.detailsFace.style.visibility = 'hidden'
      } else if (percent === 1) {
        this.topFace.style.visibility = 'hidden'
        this.bottomFace.style.visibility = 'hidden'
        this.detailsFace.style.visibility = 'visible'
      } else {
        this.topFace.style.visibility = 'visible'
        this.bottomFace.style.visibility = 'visible'
        this.detailsFace.style.visibility = 'visible'
      }

      // Fixing an issue where the shadows aren't included in backface
      if (percent > 0.5) {
        this.detailsFace.style.zIndex = 2
        this.heroFace.style.zIndex = 1
      } else {
        this.detailsFace.style.zIndex = 1
        this.heroFace.style.zIndex = 2
      }

      this.updateShadows(percent)
    }

    if (this.shadowCastToPanel) {
      this.shadowCastToPanel.updateCastShadow(percent)
    }
  }

  get revealTransitionPercent () {
    return this._revealTransitionPercent
  }

  // This is currently called from the panel -above- this one
  updateCastShadow (percent) {
    this.castShadow.updateCastShadowPercent(percent, 0.5, -0.8, 0.65, 0.75)
  }

  updateShadows (percent) {
    this.leftShadow.updateEdgeShadowPercent(percent, 'left')
    this.rightShadow.updateEdgeShadowPercent(percent, 'right')
  }

  render () {
    // Depth sorting based on which block is closest to window center
    const rect = this.panelBounds.getBoundingClientRect()
    const windowCenterY = window.innerHeight / 2
    const panelCenterY = rect.y + rect.height / 2

    const dist = Math.round(Math.abs(windowCenterY - panelCenterY))

    if (dist <= window.innerHeight) {
      this.DOM.style.zIndex = 10000 - dist
    }
  }

  

  onToggleInView (triggerInstance) {
    const isActive = triggerInstance.isActive

    // Timeout is used to prevent faces from revealing during quick scrolling
    clearTimeout(this.revealTimeoutRef)

    if (this.enable3dFaces) {
      if (isActive) {
        
        this.revealTimeoutRef = setTimeout(() => {
          this.revealDetailsFace()
        }, 1000)
        
      } else {
        this.hideDetailsFace()
      }
    }
  }

  revealDetailsFace (time = 1.5) {
    this.detailsRevealed = true

    // Skip tweening if at 100%, this in the case when the user drags the panel
    if (this.revealTransitionPercent === 1) {
      this.onRevealComplete()
    } else {
      this.tweenToPercent(time, 1)
    }
  }

  hideDetailsFace (time = 1.5) {
    this.detailsRevealed = false

    // Skip tweening if at 100%, this in the case when the user drags the panel
    if (this.revealTransitionPercent === 0) {
      this.onRevealComplete()
    } else {
      this.tweenToPercent(time, 0)
    }
  }

  tweenToPercent (time, percent) {
    gsap.to(this, time, {
      ease: this.customEase,
      revealTransitionPercent: percent,
      onComplete: this.onRevealComplete.bind(this),
      overwrite: true
    })
  }

  /**
  * Called whenever reveal is complete, either through tweening or dragging
  */
  onRevealComplete () {
    this.isRevealing = false
  }
}
