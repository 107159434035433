import Template from '../partials/AboutPanel.template.ejs'
import BasePanel from './BasePanel'
import { gsap, ScrollToPlugin } from 'gsap/all' // eslint-disable-line no-unused-vars
import CustomEase from '../../lib/CustomEase.js'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(CustomEase)

export default class AboutPanel extends BasePanel {
  constructor (index, aboutProperties) {
    super(index, aboutProperties, Template)

    // Referencing by class name because this is initialized before DOM is added to document
    this.DOM.querySelector('.about-btn').href = 'https://www.linkedin.com/in/nicholasmitrousis/'
    this.DOM.querySelector('.about-btn').target = '_blank'

    this.DOM.querySelector('.work-btn').href = '#'
    this.DOM.querySelector('.work-btn').addEventListener('click', (e) => {
      e.preventDefault()
      this.scrollToFirstPanel()
    })

    window.doIt = () => {
      this.scrollToFirstPanel()
    }
  }

  scrollToFirstPanel () {
    gsap.to(window, {
      duration: 1,
      autoKill: true,
      scrollTo: '.base-panel:nth-of-type(3) .panel-bounds',
      ease: CustomEase.create('about', 'M0,0 C0.718,0.364 0.458,1 1,1 ')
    })
  }
}
