import ConfigManager from './ConfigManager'
import ProjectPanel from './ProjectPanel'
import AboutPanel from './AboutPanel'

export default class PageLayout {
  constructor () {
    this.allPanels = []
    this.panelsContainerDOM = document.getElementById('panels-container')
    this.panelsContainerDOM.style.perspective = '1000px'

    // Set up about first
    // This could be moved to a template someday and dynamically
    // creat all the sections, which is fancy
    const aboutPanel = new AboutPanel(0, ConfigManager.config.about)
    this.allPanels.push(aboutPanel)
    this.panelsContainerDOM.appendChild(aboutPanel.DOM)

    // Rest of projects
    // Index starts at 1 because of about panel, above
    ConfigManager.projects.forEach((project, index) => {
      const projectPanel = new ProjectPanel(index + 1, project)
      this.allPanels.push(projectPanel)

      this.panelsContainerDOM.appendChild(projectPanel.DOM)
    })

    // Set bottom panel of each to cast shadows onto (for now)
    // Done this way to ensure the shadows are included in the z-depth calcs
    this.allPanels.forEach((basePanel, index) => {
      const castToIndex = index + 1
      if (castToIndex < this.allPanels.length) {
        basePanel.shadowCastToPanel = this.allPanels[castToIndex]
      }
    })

    this.render()
    this.lazyLoadImages()
  }

  render () {
    this.panelsContainerDOM.style.perspectiveOrigin = `50% ${this.panelsContainerDOM.offsetTop + window.scrollY + (window.innerHeight / 2)}px`

    this.allPanels.forEach((basePanel) => {
      basePanel.render()
    })

    window.requestAnimationFrame(() => {
      this.render()
    })
  }

  // Sets an observer for any divs with a lazy load attribute
  lazyLoadImages () {
    const options = {
      threshold: 0.05,
      rootMargin: '25%'
    }

    const lazyObjectObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetDiv = entry.target
            if (targetDiv.getAttribute('data-bg-lazyload')) {
              console.log('load', targetDiv.getAttribute('data-bg-lazyload'))
              targetDiv.style.backgroundImage = `url(${targetDiv.getAttribute('data-bg-lazyload')})`
              targetDiv.removeAttribute('data-bg-lazyload')
              lazyObjectObserver.unobserve(targetDiv)
            }
          }
        })
      }, options)

    const loadBGs = document.querySelectorAll('[data-bg-lazyload]')

    for (let i = 0; i < loadBGs.length; i++) {
      lazyObjectObserver.observe(loadBGs[i])
    }
  }
}
