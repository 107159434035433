import jsyaml from 'js-yaml'

class ConfigManager {
  constructor () {
    this.config = null
  }

  load (configYaml) {
    this.config = jsyaml.load(configYaml)

    this.prependMediaPaths()
  }

  get projects () {
    return this.config.projects
  }

  get paths () {
    return this.config.paths
  }

  prependMediaPaths () {
    this.projects.forEach((project) => {
      // If media key doesn't exist, don't process
      if (project.media) {
        Object.entries(project.media).forEach(([key, val]) => {
          project.media[key] = this.config.paths.media + project.projectMedia + val
        })
      }
    })
  }
}

export default new ConfigManager()
