import Template from '../partials/ProjectPanel.template.ejs'
import ForegroundTemplate from '../partials/ProjectHeadingForeground.template.ejs'
import BasePanel from './BasePanel'
import MediaVideo from './MediaVideo'

export default class ProjectPanel extends BasePanel {
  // Index is stacking order on page
  constructor (index, projectProperties) {
    super(index, projectProperties, Template)
    this.projectProperties = projectProperties
    this.hasShownMedia = false

    this.titleDiv = this.createDOMFromTemplate(ForegroundTemplate, projectProperties)
    this.titleDiv.style.top = `${index * 100}vh`
    document.querySelector('#panels-container').appendChild(this.titleDiv)

    // Assumes perspective in px
    this.panelsPerspective = parseInt(document.querySelector('#panels-container').style.perspective)

    window.addEventListener('resize', () => {
      this.onResize()
    })

    this.onResize()
  }

  onResize () {
    // Pushes the titles into z-space so they parallax, but also fixes
    // the left alignment so they are fully left in screen-coordinates
    const transZ = 300
    const offsetLeft = (window.innerWidth / 2) * (transZ / this.panelsPerspective)

    this.titleDiv.style.left = `${offsetLeft}px`
    this.titleDiv.style.transform = `translateZ(${transZ}px)`
  }

  onRevealComplete () {
    // Details view is completely visible
    if (this._revealTransitionPercent === 1) {
      // This play has no effect if the user clicked on "view"
      // only when they have dragged the face
      this.initAndPlayVideo()
      // if (this.mediaVideo) this.mediaVideo.playVideo()
      this.hasShownMedia = true
    }

    super.onRevealComplete()
  }

  initAndPlayVideo () {
    if (this.projectProperties.media.video) {
      // The same video element is re-used throughout so that each video doesn't
      // need to stay in memory, so this is initialized each time
      this.mediaVideo = new MediaVideo(this.projectProperties.media.video)
      this.DOM.querySelector('.details-video').appendChild(this.mediaVideo.element)
      this.mediaVideo.playVideo()
    }
  }

  set revealTransitionPercent (percent) {
    // Stopping the video after a partial rotation
    if (this.hasShownMedia && percent < 0.5) {
      if (this.mediaVideo) this.mediaVideo.resetVideo()
      // Clear reference for GC
      this.mediaVideo = null
      this.hasShownMedia = false
    }

    super.revealTransitionPercent = percent
  }

  // Must override matching getter
  get revealTransitionPercent () {
    return this._revealTransitionPercent
  }
}
