import mainCSS from '../css/main.scss' // eslint-disable-line no-unused-vars
import axios from 'axios'

import ConfigManager from './ConfigManager'
import PageLayout from './PageLayout'

// Having issues with chrome overriding the $
window.$ = $

main()

function main () {
  axios
    .get('./config.yml')
    .then(res => {
      ConfigManager.load(res.data)

      const pageLayout = new PageLayout() // eslint-disable-line no-unused-vars
    })
}
