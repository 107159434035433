import EventEmitter from 'eventemitter3'

// const DOM = document.createElement('template')
let video = null
// DOM.appendChild(video)

export default class MediaVideo extends EventEmitter {
  constructor (videoPath) {
    super()

    if (!video) {
      video = document.createElement('video')
      // Autoplay removed on purpose
      video.loop = true
      video.muted = true
      video.playsInline = true

      window.theVideo = video
    }

    video.src = videoPath
  }

  get element () {
    return video
  }

  playVideo () {
    // There's a quirk with autoplay on ios for how this video is
    // handled; requires this play vs. autoplay attribute
    if (video.paused) {
      video.play()
        .catch((error) => {
          console.log(error)
        })
    }
  }

  resetVideo () {
    if (!video.paused) {
      video.pause()
    }

    video.currentTime = 0
  }

  // createDOMFromTemplate (ejsTemplate, properties) {
  //   const template = document.createElement('template')
  //   template.innerHTML = ejs.render(ejsTemplate, properties)
  //   return template.content.firstChild
  // }
}
